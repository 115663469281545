import React from 'react';
import ReactModal from 'react-modal';
import examinationIcon from '../../../assets/images/examinationIcon.svg';

export const ScheduleConfirmationModal = ({
  scheduleConfirmationModal,
  closeScheduleConfirmationModal,
  confirmationCallback, // Add confirmation callback prop
  actionType,
}) => {
  const handleConfirm = () => {
    closeScheduleConfirmationModal();
    if (confirmationCallback) {
      confirmationCallback(true); // Pass true to indicate confirmation
    }
  };

  const handleCancel = () => {
    closeScheduleConfirmationModal();
    if (confirmationCallback) {
      confirmationCallback(false); // Pass false to indicate cancellation
    }
  };

  const confirmationMessage = () => {
    return (
      <p className="mt-3 font-semibold">
        {actionType === 'holiday' ? (
          <>
            Are you sure you want to add the holiday? You already have
            appointment booked for that day, and adding the holiday will cancel
            your scheduled appointment.
          </>
        ) : actionType === 'editShift' ? (
          <>
            Are you sure you want to change the working hours? You already have
            appointment booked at that time, and changing the working hours will
            cancel those appointments.
          </>
        ) : (
          <>
            Are you sure you want to change the working hours? If you have
            appointments already booked, it will cancel all appointments.
          </>
        )}
      </p>
    );
  };

  return (
    <>
      <ReactModal
        isOpen={scheduleConfirmationModal}
        onRequestClose={closeScheduleConfirmationModal}
        className={'md:w-3/12 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 99,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <span
          className=" absolute right-4 top-2 font-semibold cursor-pointer"
          onClick={closeScheduleConfirmationModal}
        >
          &#x2715;
        </span>
        <div className="text-center mt-3 flex flex-col justify-center items-center">
          <img src={examinationIcon} alt="Examination Icon" />
          {confirmationMessage()}
        </div>
        <div className="grid grid-cols-2 gap-4  mt-8">
          <button
            className="h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
            onClick={handleCancel} // Call handleCancel on cancel button click
          >
            Cancel
          </button>
          <button
            className="h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
            onClick={handleConfirm} // Call handleConfirm on confirm button click
          >
            Confirm
          </button>
        </div>
      </ReactModal>
    </>
  );
};
