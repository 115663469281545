import React, { useEffect, useState } from 'react';
import { useGetBuyerContacts } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from './modal';
import BookAppointmentForm from '../../components/templates/book-appointment-form/book-appointment-form';
import { Spinner } from '../../components/atoms';

const ClientDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const buyerUid = location.state;
  const [buyerList, setBuyerList] = useState();

  const { loading, data, refetch } = useGetBuyerContacts(buyerUid);

  useEffect(() => {
    if (data?.buyerFamilyMembers) {
      setBuyerList(data?.buyerFamilyMembers);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [buyerUid, refetch]);

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);

  const openAppointmentModal = () => {
    setIsAppointmentModalOpen(true);
  };

  const closeAppointmentModal = () => {
    setIsAppointmentModalOpen(false);
  };

  const handleClick = () => navigate(-1);
  const formatPhoneNumber = (phoneNumber) => {
    const match = phoneNumber?.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  return (
    <>
      {loading && <Spinner />}
      <div>
        <section className="flex flex-col relative  bg-gray-verylight md:h-screen h-svh md:pt-0 md:bg-gray-main px-3 md:px-0">
          <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
            <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
              <div className="flex justify-between items-center">
                <div>
                  <div className="hidden md:block">
                    <span
                      className="text-xs text-gray-middle cursor-pointer"
                      onClick={handleClick}
                    >
                      Clients
                    </span>
                    <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                    <span className="text-xs text-gray-dark">
                      Client Details
                    </span>
                  </div>
                  <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                    Client Details
                  </h1>
                </div>
                <div className="flex flex-row gap-x-3 md:h-7 lg:h-10">
                  <button
                    className=" px-2 pt-1 pb-1  ml-2  sm:ml-0 md:h-7 h-7 lg:pt-2 lg:pb-2 lg:h-10 whitespace-nowrap guide-add-buyer-button   md:border md:border-primary md:bg-primary  text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                    onClick={() => {
                      openAppointmentModal();
                    }}
                  >
                    <div className="flex items-center gap-2 w-full justify-center   text-14 md:block hidden">
                      <span>+&nbsp;</span>
                      <span>Book An Appointment </span>
                    </div>
                    <div className="md:hidden block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M4.66446 1.50853C4.66446 1.22745 4.89053 1 5.16991 1C5.31404 1 5.44385 1.06059 5.53616 1.15792C5.62254 1.2493 5.67535 1.37245 5.67535 1.50853V2.36567H8.70801V1.50853C8.70801 1.22745 8.93408 1 9.21345 1C9.49283 1 9.7189 1.22745 9.7189 1.50853V2.36567H12.7516V1.50853C12.7516 1.22745 12.9776 1 13.257 1C13.4219 1 13.568 1.07946 13.6603 1.20162C13.7244 1.28704 13.7624 1.39331 13.7624 1.50853V2.36567H14.8859C16.2872 2.36567 17.4269 3.51184 17.4269 4.9222V9.94388C17.4269 10.224 17.2008 10.4524 16.9215 10.4524C16.6421 10.4524 16.416 10.224 16.416 9.94388V4.9222C16.416 4.073 15.7294 3.38272 14.8859 3.38272H13.7624V4.23887C13.7624 4.51995 13.5364 4.74739 13.257 4.74739C12.9776 4.74739 12.7516 4.51995 12.7516 4.23887V3.38272H5.67535V4.23887C5.67535 4.37494 5.62204 4.49909 5.53517 4.59047C5.44336 4.68681 5.31354 4.74739 5.16991 4.74739C4.89053 4.74739 4.66446 4.51995 4.66446 4.23887V3.38272H3.54153C2.69748 3.38272 2.01089 4.073 2.01089 4.9222V14.9656C2.01089 15.8138 2.69748 16.505 3.54153 16.505L10.1542 16.507C10.3319 16.507 10.4879 16.5984 10.5778 16.7375C10.6296 16.8169 10.6597 16.9123 10.6597 17.0146C10.6597 17.2947 10.4336 17.5221 10.1542 17.5221H3.54153C2.14021 17.5221 1 16.3749 1 14.9656V4.9222C1 3.51184 2.14021 2.36567 3.54153 2.36567H4.66446V1.50853Z"
                          fill="#232429"
                        ></path>
                        <path
                          d="M5.09784 9.36981H13.2916C13.4426 9.36981 13.5783 9.30326 13.6711 9.19699C13.7496 9.1076 13.797 8.9904 13.797 8.86128C13.797 8.5802 13.5709 8.35276 13.2916 8.35276H5.09784C4.81847 8.35276 4.5924 8.5802 4.5924 8.86128C4.5924 9.14236 4.81847 9.36981 5.09784 9.36981Z"
                          fill="#232429"
                        ></path>
                        <path
                          d="M9.7189 12.3137C9.7189 12.0316 9.49283 11.8052 9.21345 11.8052H5.09784C4.81847 11.8052 4.5924 12.0316 4.5924 12.3137C4.5924 12.5938 4.81847 12.8222 5.09784 12.8222H9.21345C9.3413 12.8222 9.45828 12.7745 9.54762 12.6961C9.65275 12.6027 9.7189 12.4657 9.7189 12.3137Z"
                          fill="#232429"
                        ></path>
                        <path
                          d="M15.5784 14.6458H17.0661C17.2783 14.6458 17.4501 14.8186 17.4501 15.0321C17.4501 15.1324 17.4121 15.2238 17.3494 15.2923C17.2793 15.3688 17.1786 15.4175 17.0661 15.4175H15.0675C14.9466 15.4175 14.8331 15.3609 14.7605 15.2635C14.6879 15.1672 14.6652 15.0411 14.6988 14.9239L15.2951 12.851C15.3538 12.6464 15.569 12.5272 15.7704 12.5878C15.9743 12.6474 16.0917 12.861 16.0325 13.0665L15.5784 14.6458Z"
                          fill="#232429"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.1227 15.0381C11.1227 12.853 12.8898 11.0752 15.0616 11.0752C17.2329 11.0752 19 12.853 19 15.0381C19 17.2231 17.2329 19 15.0616 19C12.8898 19 11.1227 17.2231 11.1227 15.0381ZM11.8902 15.0381C11.8902 16.7971 13.3128 18.2283 15.0616 18.2283C16.8099 18.2283 18.232 16.7971 18.232 15.0381C18.232 13.2791 16.8099 11.8479 15.0616 11.8479C13.3128 11.8479 11.8902 13.2791 11.8902 15.0381Z"
                          fill="#232429"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
          <section className="relative flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
            <div className="w-full overflow-y-auto mt-4">
              <div className="infinite-scroll-component__outerdiv">
                <div
                  className="infinite-scroll-component"
                  id="scrollable-container"
                  style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
                >
                  <table className="w-full main-table border-separate main-table">
                    <thead className="bg-gray-verylight sticky top-0 z-10">
                      <tr className="border-b border-gray-highlight ">
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left pl-4">
                          Client <span className=" md:inline hidden">Name</span>
                        </th>
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left md:table-cell hidden">
                          Relationship
                        </th>
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left md:table-cell hidden">
                          Phone Number
                        </th>
                        {/* <th className="text-r0.625 px-4 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left md:table-cell hidden">
                          Email
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {buyerList?.length ? (
                        buyerList?.map((item) => (
                          <>
                            <tr className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item  items-center lg1:mb-2">
                              <td className="font-semibold text-xs text-black-secondary py-4 pl-4">
                                <div className="grid gap-3">
                                  <span
                                    className="capitalize no-auto-link"
                                    data-detector="false"
                                  >
                                    {item?.formalName}
                                  </span>
                                  <span
                                    className="md:hidden table-cell truncate no-auto-link"
                                    data-detector="false"
                                  >
                                    {item?.email}
                                  </span>
                                  <span
                                    className="md:hidden table-cell no-auto-link"
                                    data-detector="false"
                                  >
                                    {formatPhoneNumber(item?.phone)}
                                  </span>
                                </div>
                              </td>
                              <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden capitalize">
                                {item?.relationType === 'myself'
                                  ? '-'
                                  : item?.relationType}
                              </td>
                              <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden">
                                {formatPhoneNumber(item?.phone)}
                              </td>
                              {/* <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden truncate">
                                {item?.email}
                              </td> */}
                            </tr>
                          </>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="h-50 w-full text-center">
                            <p className="font-semibold">No Client’s Found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      <Modal isOpen={isAppointmentModalOpen} onClose={closeAppointmentModal}>
        <h2 className="font-semibold md:text-2xl text-base text-gray-dark cursor-pointer">
          Book an appointment
        </h2>

        <BookAppointmentForm
          closeAppontmentModal={closeAppointmentModal}
          refetch={refetch}
        />
      </Modal>
    </>
  );
};

export default ClientDetails;
