import React from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiImagesDropzone } from './multi-images-dropzone';
import { ItemImage } from './item-image';
import { InputLabel } from '../../atoms';
import { SECONDARY_IMAGES_PER_PRODUCT } from '../../../constants';

// usage example
// specify name of field that has image itself
// and field that contains blobId for backend
// <FormImage size="full" name="imageUrl" blobIdName="blobSignedId" />;
const PRIMARY_IMAGE = 'primaryImage';
const IMAGES = 'images';

export const MultiImagesForm = () => {
  const { setValue, watch } = useFormContext();

  const primaryImageValue = watch(PRIMARY_IMAGE);
  const imagesValue = watch(IMAGES) || [];

  const handleAddingImage = (newValue) => {
    setValue(IMAGES, [...imagesValue, { url: newValue }]);
  };

  const removeSecondaryImage = (selectedImage) => {
    setValue(
      IMAGES,
      imagesValue.filter((image) => image.url !== selectedImage.url)
    );
  };

  const setAsPrimaryImageHandler = (index) => {
    const newPrimaryImage = {
      ...imagesValue[index],
    };
    const newImages = [...imagesValue];
    newImages[index] = primaryImageValue;
    setValue(PRIMARY_IMAGE, newPrimaryImage);
    setValue(IMAGES, newImages);
  };

  return (
    <div className="w-full">
      <div className="mb-1 text-left">
        <InputLabel label="Product Image" />
      </div>
      <div className="flex flex-wrap gap-4 md:max-h-29 md:overflow-y-hidden">
        {imagesValue.length < SECONDARY_IMAGES_PER_PRODUCT && (
          <MultiImagesDropzone
            className="flex flex-wrap gap-4 md:max-h-29 md:overflow-y-hidden"
            handleAddingImage={handleAddingImage}
          />
        )}
        {imagesValue &&
          imagesValue?.map((image, index) => (
            <ItemImage
              onFileRemove={() => removeSecondaryImage(image)}
              key={image.url}
              value={image}
              index={index}
              setAsPrimaryImageHandler={setAsPrimaryImageHandler}
            />
          ))}
      </div>
    </div>
  );
};
