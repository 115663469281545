import React, { useRef, useState, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAppointmentsCountForHoliday,
  useCreateHoliday,
} from '../../../hooks';
import { holidayFormSchema } from '../../../constants';
import { showError } from '../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ScheduleConfirmationModal } from '../../../components/atoms/schedule-confirmation-modal/confirmation-modal';
import moment from 'moment';

const convertToISO = (dateString) => {
  return moment(dateString).format('YYYY-MM-DD');
};
export const HolidayForm = ({
  setIsLoading,
  setAddHoliday,
  closeHolidayModal,
}) => {
  const ref = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus(), // Expose a focus function
  }));

  const [apptCount, setApptCount] = useState(0);
  const [scheduleConfirmationModal, setScheduleConfirmationModal] =
    useState(false);

  const [servicePayload, setServicePayload] = useState({});

  const openScheduleConfirmationModal = (count) => {
    setApptCount(count);
    setScheduleConfirmationModal(true);
  };

  const closeScheduleConfirmationModal = () => {
    setScheduleConfirmationModal(false);
  };

  const handleCreateHoliday = useCreateHoliday();
  const { handleFetchAppointmentsCount } = useAppointmentsCountForHoliday();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(holidayFormSchema),
  });
  const [error, setError] = useState('');

  const createNewHoliday = async (payload) => {
    setIsLoading(true);
    const response = await handleCreateHoliday(payload ?? servicePayload);
    if (response?.data?.createHoliday) {
      setAddHoliday(response?.data?.createHoliday);
      closeHolidayModal();
      reset();
      setIsLoading(false);
    } else {
      // Displaying the error message
      setIsLoading(false);
      showError(response);
    }
  };

  const confirmationCallback = (confirm) => {
    if (!confirm) {
      // closeHolidayModal();
      return;
    }
    createNewHoliday();
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      startDate: convertToISO(data?.startDate),
      endDate: convertToISO(data?.endDate),
      title: data?.title,
      holidayUid: '',
    };

    setServicePayload(payload);

    try {
      // Check for appointments during the holiday period
      const countResponse = await handleFetchAppointmentsCount(payload);
      setIsLoading(false);
      const appointmentCount =
        countResponse?.data?.appointmentsForHoliday?.appointmentsCount ?? 0; //Enbale the following when get response from API
      // const appointmentCount = 1;
      if (appointmentCount > 0) {
        // Opening confirmation modal if there is any holiday.
        openScheduleConfirmationModal(appointmentCount);
        return;
      }

      // Create the holiday
      createNewHoliday(payload);
    } catch (error) {
      console.error('Error during holiday creation:', error);
      setIsLoading(false);
      showError(error);
    }
  };

  return (
    <>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className="text-sm  text-gray-dark">Title</label>
          <Controller
            name="title"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2"
                placeholder="Title"
              />
            )}
          />
          {errors && errors?.title && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.title?.message}
            </p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="grid w-full relative">
            <label className="text-sm text-gray-dark">Start Date</label>

            <Controller
              name="startDate"
              control={control}
              defaultValue="" // provide a default value if necessary
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="mm/dd/yyyy"
                  minDate={new Date()}
                  ref={ref}
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  className="w-full min-w-full cursor-default text-left h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2 "
                  onBlur={(e) => {
                    field.onBlur(e);
                    setError(null); // Reset any previous errors
                  }}
                />
              )}
            />
            {errors && errors?.startDate && (
              <p className="text-red-drk md:text-r0.6875 text-r0.625 top-16 absolute">
                {errors?.startDate?.message}
              </p>
            )}
          </div>

          <div className="grid w-full relative">
            <label className="text-sm text-gray-dark">End Date</label>
            <Controller
              name="endDate"
              control={control}
              defaultValue="" // provide a default value if necessary
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="mm/dd/yyyy"
                  ref={ref}
                  minDate={watch('startDate') || new Date()}
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  className="w-full min-w-full cursor-default text-left h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2 "
                  onBlur={(e) => {
                    field.onBlur(e);
                    setError(null); // Reset any previous errors
                  }}
                />
              )}
            />
            {errors && errors?.endDate && (
              <p className="text-red-drk md:text-r0.6875 text-r0.625 top-16 absolute">
                {errors?.endDate?.message}
              </p>
            )}
          </div>
        </div>
        <button
          className={
            !isValid
              ? 'text-gray-dark w-full p-2 mt-12 rounded-lg bg-gray-mercury'
              : ' w-full p-2 mt-12 rounded-lg bg-primary text-white-main hover:bg-blue-hover'
          }
          disabled={!isValid}
        >
          Save
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
      {/* Apoointment Confirmation Modal */}
      <ScheduleConfirmationModal
        apptCount={apptCount}
        scheduleConfirmationModal={scheduleConfirmationModal}
        closeScheduleConfirmationModal={closeScheduleConfirmationModal}
        confirmationCallback={confirmationCallback}
        actionType="holiday"
      />
    </>
  );
};
