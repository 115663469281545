import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CreateAppointmentNoteSchema } from '../../constants';
import 'react-datepicker/dist/react-datepicker.css';
import { showError, showSuccess } from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';

export const CreateAppointmentNote = ({
  setIsLoading,
  appointmentDetails,
  handleCreateAppointmentNote,
  setAppointmentDetails,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAppointmentNoteSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      appointmentUid: appointmentDetails?.uid,
      note: data?.sellerNote,
    };
    const response = await handleCreateAppointmentNote(payload);
    if (response?.data?.createAppointmentNote) {
      setAppointmentDetails(response?.data?.createAppointmentNote);
      showSuccess('note added successfully');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  console.log('appointmentDetails == ', appointmentDetails);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-4 relative">
        <Controller
          name="sellerNote"
          control={control}
          render={({ field }) => (
            <textarea
              {...field}
              className="w-full text-xs font-medium rounded border border-gray-light console-input p-2 my-3"
              rows="4"
              cols="50"
            />
          )}
        />
        {errors && errors?.sellerNote && (
          <p className="text-red-drk md:text-r0.6875 text-r0.625 top-24 absolute">
            {errors?.sellerNote?.message}
          </p>
        )}
      </div>
      <div className="mt-3 flex justify-end">
        <button
          type="submit"
          className="flex-1 md:flex-none h-10 md:w-40 w-full border border-primary bg-primary text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
        >
          Submit
        </button>
      </div>
    </form>
  );
};
