import React from 'react';
import { Navigate } from 'react-router-dom';

import { PermissionCheck } from '..';
import { MainTemplate } from '../../templates';
import { ROUTING_CONFIG } from '../../../constants';

export const PrivateRoute = ({
  children,
  role,
  roles,
  notRole,
  hasMainTemplate = true,
}) => {
  return (
    <PermissionCheck
      role={role}
      roles={roles}
      notRole={notRole}
      fallback={<Navigate to={ROUTING_CONFIG.main} />}
    >
      {hasMainTemplate ? (
        <MainTemplate>{children}</MainTemplate>
      ) : (
        <>{children}</>
      )}
    </PermissionCheck>
  );
};
