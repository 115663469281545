import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useBlobUpload, useLoadImage, useCropper } from '../../../hooks';

import { EditableImageTemplate } from './editable-image.template';
import { CropperModal } from '../cropper-modal';

export const EditableImageWithCropper = ({
  value,
  onChange,
  onBlobIdChange,
  cropperProps = {},
  extendImage = false,
  setCropperBlob,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const { image, handleGetImage } = useLoadImage({
    onError: () =>
      toast.error(
        'Image format is not valid. The supported format is jpg,jpeg,png'
      ),
    extendToAspect: extendImage && cropperProps?.aspect,
  });
  const handleRemoveImage = () => {
    onChange?.(null);
    onBlobIdChange?.(null);
  };
  const { loading } = useBlobUpload({
    onChange,
    onBlobIdChange,
  });

  const { croppingImage, croppedBlob, handleCroppingImageChange } =
    useCropper(image);

  const handleFilePickerClick = useCallback(
    async (file) => {
      if (file.type.includes('gif') || file.type.includes('svg')) {
        return toast.error(
          'Image format is not valid. The supported format is jpg,jpeg,png'
        );
      }
      const imageFromDisc = await handleGetImage(file);
      !!imageFromDisc && setIsOpen(true);
    },
    [setIsOpen]
  );

  const handleCroppedImageUpload = async () => {
    setCropperBlob(croppedBlob);
    onChange?.(croppingImage);
    // const response = await handleImageUpload(croppedBlob);
    // setSignedBlobId(response);
    // console.log('response ==== ', response);
    setIsOpen(false);
  };

  return (
    <EditableImageTemplate
      {...rest}
      loading={loading}
      image={value}
      onFileRemove={handleRemoveImage}
      onFileSelect={handleFilePickerClick}
    >
      {image && (
        <CropperModal
          isOpen={isOpen}
          image={image}
          handleCroppingImageChange={handleCroppingImageChange}
          handleCroppedImageUpload={handleCroppedImageUpload}
          disabled={!croppedBlob}
          cropperProps={cropperProps}
          handleClose={handleClose}
        />
      )}
    </EditableImageTemplate>
  );
};
