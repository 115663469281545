import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import storeIcon from '../../assets/images/store.svg';
import AppointmentAnalysis from './appointmentAnalysis';
import TotalAppointmentChart from './totalAppointmentChart';
import { UpcomingAppointments } from './upcomingAppointments';
import { useGetDashboardStatistics } from '../../hooks/dashboard/use-get-dashboard-statistics';
import { useGetSellerConsoleLink } from '../../hooks';
import { storageService } from '../../services';

export const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(
    `${currentYear}-01-01T00:00:00.000Z`
  ).toISOString();
  const endOfYear = new Date(
    `${currentYear}-12-31T23:59:59.999Z`
  ).toISOString();

  const [filter, setFilter] = useState({
    startTimeGteq: startOfYear,
    startTimeLteq: endOfYear,
  });

  const { loading, data, refetch } = useGetDashboardStatistics(filter);

  useEffect(() => {
    if (data?.dashboardStatistics) {
      setDashboardData(data?.dashboardStatistics);
    }
  }, [data]);

  useEffect(() => {
    if (selectedStartDate !== null && selectedEndDate !== null) {
      setFilter({
        ...filter,
        startTimeGteq: selectedStartDate,
        startTimeLteq: selectedEndDate,
      });
    } else {
      setFilter({
        ...filter,
        startTimeGteq: startOfYear,
        startTimeLteq: endOfYear,
      });
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const { refetch: linkRefetch } = useGetSellerConsoleLink();

  const handleRedirect = async (e) => {
    e.preventDefault();
    const { data } = await linkRefetch();
    storageService.clearAll();
    window.location.href = data?.sellerConsoleLink?.url;
  };

  return (
    <>
      <section className="flex flex-col relative bg-gray-verylight md:h-screen h-svh md:pt-0 md:bg-gray-main px-3 md:px-0">
        <div className="pl-0 pr-0 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div className="flex justify-between w-full items-center gap-3">
                <h1 className="font-bold text-lg text-gray-dark text-left md:text-xl lg:text-2xl truncate">
                  Scheduler Dashboard
                </h1>
                <button
                  className="flex whitespace-nowrap gap-2 justify-center md:flex-none px-2 py-1 ml-2 sm:w-40 sm:px-0 sm:ml-0 md:h-7 lg:py-2 lg:h-10 h-7 w-auto border border-primary bg-primary text-white-main text-xs hover:bg-blue-hover md:py-2 items-center text-center font-bold rounded-md"
                  onClick={handleRedirect}
                >
                  <img src={storeIcon} alt="icon" />
                  <span>My Online Store</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light md:border-b-2 md:border-gray-border"></div>
        <section className="grid grid-dashbord gap-4 md:pl-8 md:pr-8 mt-8 overflow-y-auto pb-8">
          <div className="p-2 pt-0 pb-0 rounded">
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-white-main px-8 py-6 rounded shadow-dashBox border border-blue-draft">
                <h2 className="font-bold lg:text-4xl text-lg text-gray-dark">
                  {dashboardData?.todaysAppointmentCount || 0}
                </h2>
                <p className="lg:text-lg text-sm text-gray-dark font-semibold mt-3">
                  Today’s Appointments
                </p>
              </div>
              <div className="bg-white-main px-8 py-6 rounded shadow-dashBox border border-blue-draft">
                <h2 className="font-bold lg:text-4xl text-lg text-gray-dark">
                  {dashboardData?.totalCompletedAppointmentCount || 0}
                </h2>
                <p className="lg:text-lg text-sm text-gray-dark font-semibold mt-3">
                  Completed Appointments
                </p>
              </div>
            </div>
            <AppointmentAnalysis
              data={dashboardData?.pieChartData}
              onDateRangeChange={(startDate, endDate) => {
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
              }}
              loading={loading}
            />
            <TotalAppointmentChart barGraphData={dashboardData?.barGraphData} />
          </div>
          <UpcomingAppointments />
        </section>
      </section>
    </>
  );
};

export default Dashboard;
